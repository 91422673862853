<template>
  <section>
    <b-tabs
      content-class="mt-1"
      align="center"
    >
      <b-tab title="Banimento de Contas">
        <BanishmentsPerAccount />
      </b-tab>

      <b-tab title="Banimento por IP">
        <BanishmentsPerIp />
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import BanishmentsPerAccount from './BanishmentsPerAccount/Index.vue'
import BanishmentsPerIp from './BanishmentsPerIp/Index.vue'

export default {
  components: {
    BTabs,
    BTab,
    BanishmentsPerAccount,
    BanishmentsPerIp
  }
}
</script>
