<template>
  <b-overlay
    id="overlay-background"
    :show="loading"
    variant="transparent"
    opacity="1"
    blur="5px"
    rounded="sm"
  >
    <b-row>
      <b-col
        md="3"
        sm="4"
        class="my-1"
      >
        <b-form-group class="mb-0">
          <label
            class="d-inline-block text-sm-left mr-50"
          >Por Página</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-25"
          />
        </b-form-group>
      </b-col>

      <b-col
        md="5"
        sm="8"
        class="my-1"
      >
        <b-form-group
          v-if="sortOptions.length > 0"
          label="Ordenar Por"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">
                  -- Nenhum --
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
              class="w-25"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col
        md="4"
        class="my-1"
      >
        <b-input-group
          v-if="sortOptions.length > 0"
          size="sm"
        >
          <b-input-group-prepend>
            <b-input-group size="sm">
              <b-form-select
                v-model="filterBy"
                :options="sortOptions"
                size="sm"
                class="w-25"
              />
            </b-input-group>
          </b-input-group-prepend>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Escreva o que deseja pesquisar."
          />
        </b-input-group>
      </b-col>

      <b-col cols="12">
        <b-table
          ref="selectableTable"
          striped
          hover
          :sticky-header="isMobile"
          small
          :per-page="perPage"
          :items="items"
          :fields="fields"
          @sort-changed="sortingChanged"
        >
          <template
            v-for="field in fields"
            v-slot:[`cell(${field.key})`]="{ item }"
          >
            <slot
              :name="field.key"
              :items="item"
            />
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
        class="mt-1"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import IndexMixin from './mixins/IndexMixin'
import TableMixin from './mixins/TableMixin'

export default {
  mixins: [IndexMixin, TableMixin],
  computed: {
    isMobile () {
      return window.innerWidth < 800
    }
  }
}
</script>
