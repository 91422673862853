<template>
  <section>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group>
                <b-form-select
                  v-model="filterBy"
                  :options="filterOptions"
                  size="md"
                  class="w-25"
                />
              </b-input-group>
            </b-input-group-prepend>
            <vue-autosuggest
              id="email"
              ref="autocomplete"
              v-model="query"
              class="w-75"
              autofocus
              :suggestions="suggestions"
              :get-suggestion-value="getSuggestionValue"
              :input-props="inputProps"
              @input="onInputChange"
              @selected="onSelected"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                {{ suggestion.item.personal_data.full_name }} |
                {{ suggestion.item.email }} |
                {{ suggestion.item.personal_data.phone }} |
                {{ suggestion.item.balance.amount | currency }}
              </div>
            </vue-autosuggest>

            <b-button
              class="ml-1"
              variant="relief-danger"
              :disabled="loading"
              @click.stop="ban()"
            >
              <span v-if="!loading">Banir</span>
              <b-spinner
                v-else
                small
              />
            </b-button>
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>

    <Table v-if="table" />
  </section>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BSpinner
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import AutoSuggestMixin from '../mixins/AutoSuggestMixin'
import Table from './Table.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    VueAutosuggest,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BSpinner,
    Table
  },
  mixins: [AutoSuggestMixin],
  data: () => ({
    loading: false,
    table: true
  }),
  methods: {
    async ban () {
      const url = `/admin/users/ban/${this.selected.id}`
      this.loading = true

      await this.$http.get(url)
        .then(() => {
          this.$swal({
            title: 'Usuário banido com sucesso!',
            icon: 'success',
            confirmButtonText: 'Fechar',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .finally(() => {
          this.loading = false

          this.table = false

          setTimeout(() => {
            this.table = true
          }, 100)
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
