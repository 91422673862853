<template>
  <b-card>
    <TablePagination
      v-if="table"
      v-model="items"
      url="/admin/users/get_banned_users/paginate"
      :per-page="10"
      :fields="fields"
    >
      <template v-slot:id="data">
        {{ data.items.id }}
      </template>

      <template v-slot:name="data">
        {{ data.items.user.personal_data.full_name }}
      </template>

      <template v-slot:email="data">
        {{ data.items.user.email }}
      </template>

      <template v-slot:actions="data">
        <b-button
          variant="relief-danger"
          size="sm"
          :disabled="loading"
          @click.stop="desban(data.items.user.id)"
        >
          <span v-if="!loading">Desbanir</span>
          <b-spinner
            v-else
            small
          />
        </b-button>
      </template>
    </TablePagination>
  </b-card>
</template>

<script>
import { BCard, BButton, BSpinner } from 'bootstrap-vue'
import TablePagination from '@/components/TablePagination/Index.vue'

export default {
  components: {
    BCard,
    BButton,
    TablePagination,
    BSpinner
  },
  data: () => ({
    loading: false,
    table: true,
    items: [],
    fields: [
      {
        key: 'id',
        label: 'id'
      },
      {
        key: 'name',
        label: 'nome'
      },
      {
        key: 'email',
        label: 'email'
      },
      {
        key: 'actions',
        label: 'ações'
      }
    ]
  }),
  methods: {
    async desban (id) {
      const url = `/admin/users/unban/${id}`
      this.loading = true

      await this.$http.get(url)
        .then(() => {
          this.$swal({
            title: 'Usuário desbanido com sucesso!',
            icon: 'success',
            confirmButtonText: 'Fechar',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .finally(() => {
          this.table = false

          setTimeout(() => {
            this.table = true
          }, 100)

          this.loading = false
        })
    }
  }
}
</script>
