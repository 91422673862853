import {
  BOverlay,
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BInputGroup,
  BFormSelect,
  BPagination,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
  BFormInput
} from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BInputGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton
  },
  data: () => ({
    loading: true
  }),
  props: {
    url: {
      default: '',
      type: String,
      required: true
    },
    fields: {
      default: [],
      required: true
    }
  }
}
